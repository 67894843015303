import React, { useState, useRef, useEffect } from 'react';
import Seo from '../components/seo';
import Layout from '../components/Layout';
import BackgroundImage from 'gatsby-background-image';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Container, Row, Col, Button } from 'reactstrap';
import { Link } from 'react-scroll';

import award1 from '../images/black2021/badge_app_essentials.svg';
import award2 from '../images/black2021/badge_apple_watch.svg';
import award3 from '../images/black2021/badge_apps_we_love_right_now.svg';
import separator from '../images/black2021/separator.png';

import Slider from 'react-slick';
import arrow from '../images/black2021/arrow_right.svg';
import adviceIcon from '../images/black2021/advice_icon.svg';
import changeMealIcon from '../images/black2021/change_meal.svg';
import dietitianIcon from '../images/black2021/dietitian_icon.svg';
import dumbbellIcon from '../images/black2021/dumbbell_icon.svg';
import listIcon from '../images/black2021/list_icon.svg';
import measurementIcon from '../images/black2021/measurement_icon.svg';
import menuIcon from '../images/black2021/menu_icon.svg';
import workoutIcon from '../images/black2021/workout_icon.svg';

import coupon from '../images/black2021/coupon.png';

import pushMessage from '../images/black2021/push_message.png';
import gifAnn from '../images/black2021/gif-hpba.mp4';

import SaleProductsList from '../components/SaleProduct/SaleProductsList';
import mealVideo from '../images/black2021/meal_video.mp4';

import SaleProductsGroup from '../SaleProduct/SaleProductsGroup';

const Page = ({ data }) => {
    const heroImage = data.hero.childImageSharp.fluid;
    const heroMobile = data.heroMobile.childImageSharp.fluid;
    const gymHome = data.gymHome.childImageSharp.fluid;
    const gymHomeMobile = data.gymHomeMobile.childImageSharp.fluid;
    const [isCyberMonday, setIsCyberModay] = useState<boolean>(false);
    const [showPush, setShowPush] = useState<boolean>(false);

    const phone = useRef<HTMLDivElement>(null);
    const slider = useRef(null);

    const productsGroups = [
        new SaleProductsGroup(
            'landing:main:dietAndWorkout',
            'Dieta + trening',
            'Dostęp do planu diety i treningów.',
            'blue'
        ),
        new SaleProductsGroup(
            'landing:main:diet',
            'Dieta',
            'Dostęp tylko do planu diety.',
            'violet'
        ),
        new SaleProductsGroup(
            'landing:main:workout',
            'Trening',
            'Dostęp tylko do treningów.',
            'pink'
        ),
        new SaleProductsGroup(
            'landing:nowyrokB',
            'Dożywotni dostęp',
            'Dożywotni dostęp do planu diety, a także wszystkich obecnych i nowych zestawów treningowych.',
            'gold'
        ),
    ];

    const switchToCyberMonday = () => {
        setIsCyberModay(true);
    };

    useEffect(() => {
        const showPushMessage = () => {
            if (
                document.documentElement.clientHeight -
                    phone.current.getBoundingClientRect().height * 0.75 >
                phone.current.getBoundingClientRect().y
            ) {
                setShowPush(true);
                window.removeEventListener('scroll', showPushMessage);
            }
        };
        window.addEventListener('scroll', showPushMessage);

        return () => window.removeEventListener('scroll', showPushMessage);
    }, []);

    useEffect(() => {
        const today = new Date().getTime();
        const cyberMondayDate = new Date(2021, 10, 28).getTime();
        if (today > cyberMondayDate) {
            switchToCyberMonday();
        }
    }, []);

    const settings = {
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
        autoplay: true,
        speed: 500,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: false,
                },
            },
        ],
    };

    const handlePrev = () => {
        slider.current.slickPrev();
    };

    const handleNext = () => {
        slider.current.slickNext();
    };

    return (
        <Layout page="black-friday-2021" showMenu={true}>
            <Seo
                title="Black Friday -40% w Diet &amp; Training by Ann"
                description="Wymarzona sylwetka, dobre samopoczucie i energia. Dzięki dożywotniemu dostępowi do diety i treningów już zawsze będą one Twoje! Zagwarantuj sobie nieograniczony czasowo dostęp do wszystkich funkcji Diet &amp; Training by Ann."
                meta={[{ name: 'robots', content: 'noindex' }]}
            />

            <BackgroundImage
                Tag="section"
                fluid={heroImage}
                className="hero bgimg pt-3 d-none d-md-block"
            >
                <div className="title-box mx-auto">
                    <h1 className="mb-0 with-tag">
                        {isCyberMonday ? 'Cyber' : 'Black'} <span>2021</span>
                    </h1>
                    <h1>{isCyberMonday ? 'Monday' : 'Friday'}</h1>
                </div>
                <Container className="mt-5 mt-md-0 d-none d-md-block">
                    <Row>
                        <Col>
                            <GatsbyImage
                                image={
                                    data.trainers.childImageSharp
                                        .gatsbyImageData
                                }
                                alt=""
                            />
                            <div className="info-box text-center">
                                <p>
                                    Treningi na Twoich zasadach z najlepszymi
                                    trenerami personalnymi <br /> i dieta zawsze
                                    przy Tobie. W Twoim telefonie!
                                </p>
                                <Link
                                    to="buy"
                                    smooth={true}
                                    duration={1500}
                                >
                                    <Button
                                        color="pink-gradient"
                                        className="mt-3"
                                    >
                                        Kup dostęp taniej o 40%
                                    </Button>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </BackgroundImage>
            <BackgroundImage
                Tag="section"
                fluid={heroMobile}
                className="hero bgimg pt-3 d-md-none"
            >
                <div className="title-box mx-auto">
                    <h1 className="mb-0 with-tag">
                        {isCyberMonday ? 'Cyber' : 'Black'} <span>2021</span>
                    </h1>
                    <h1>{isCyberMonday ? 'Monday' : 'Friday'}</h1>
                </div>
                <GatsbyImage
                    image={data.trainersMob.childImageSharp.gatsbyImageData}
                    className="hero-mobile"
                    alt=""
                />
                <Container>
                    <Row>
                        <Col>
                            <div className="info-box text-center">
                                <p>
                                    Treningi na Twoich zasadach z najlepszymi
                                    trenerami personalnymi <br /> i dieta zawsze
                                    przy Tobie. W Twoim telefonie!
                                </p>
                                <Link
                                    to="buy"
                                    smooth={true}
                                    duration={1500}
                                >
                                    <Button
                                        color="pink-gradient"
                                        className="mt-3"
                                    >
                                        Kup dostęp taniej o 40%
                                    </Button>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </BackgroundImage>
            <section className="promo-info">
                <Container className="text-center">
                    <Row>
                        <Col xs="6" md="3">
                            <span>5</span>
                            <p>autorskich wersji diety Anny Lewandowskiej</p>
                        </Col>
                        <Col xs="6" md="3">
                            <span>5</span>
                            <p>trenerów dostępnych w aplikacji całą dobę</p>
                        </Col>
                        <Col xs="6" md="3">
                            <span>90+</span>
                            <p>planów treningowych - znajdź swoje ulubione!</p>
                        </Col>
                        <Col xs="6" md="3">
                            <span className="extra-line-height">3 mln</span>
                            <p>
                                użytkowników <br /> aplikacji
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="awards pb-0">
                <Container>
                    <Row>
                        <Col xs="12" md="4">
                            <img
                                src={award1}
                                className="d-block mx-auto mt-5 mt-md-0"
                                alt=""
                            />
                        </Col>
                        <Col xs="12" md="4">
                            <img
                                src={award3}
                                className="d-block mx-auto mt-5 mt-md-0"
                                alt=""
                            />
                        </Col>
                        <Col xs="12" md="4">
                            <img
                                src={award2}
                                className="d-block mx-auto mt-5 mt-md-0"
                                alt=""
                            />
                        </Col>
                    </Row>

                    <Row className="justify-content-center">
                        <div className="text-center my-5 py-5">
                            <img src={separator} alt="" className="w-100" />
                        </div>
                    </Row>
                </Container>
            </section>
            <section className="new-functionalities pt-0">
                <Container>
                    <h2 className="text-center mt-md-5">
                        Nowości <span>2021</span>
                    </h2>

                    <Row className="align-items-center">
                        <Col md="6">
                            <div className="box">
                                <div className="circle circle-pink"></div>
                                <GatsbyImage
                                    image={
                                        data.mealDetailsPhone.childImageSharp
                                            .gatsbyImageData
                                    }
                                    alt=""
                                />
                                <video
                                    className="meal-video"
                                    aria-hidden="true"
                                    muted
                                    autoPlay
                                    playsInline
                                    loop
                                >
                                    <source src={mealVideo} type="video/mp4" />
                                </video>
                            </div>
                        </Col>
                        <Col
                            md="6"
                            className="z-index-1 text-center text-md-left"
                        >
                            <div className="description-box mt-5 mt-md-0">
                                <span className="tag">Twój jadłospis</span>
                                <h3 className="mt-5 mt-md-4">
                                    dieta szyta{' '}
                                    <span>
                                        na <br /> miarę
                                    </span>
                                </h3>
                                <p className="first-paragraph mt-3 mt-md-0">
                                    Jedz tylko to, na co naprawdę masz ochotę.
                                    Tutaj wybór zawsze należy do Ciebie.
                                </p>
                                <p className="smaller-paragraph">
                                    Twój jadłospis to przepisy rozpisane na
                                    każdy dzień tygodnia, 3 lub 5 posiłków do
                                    wyboru i krótkie filmiki, które ułatwią Ci
                                    codzienne gotowanie. Wszystko rozpisane krok
                                    po kroku i indywidualnie dopasowane do
                                    Ciebie i Twoich preferencji!
                                </p>
                                <Link
                                    to="buy"
                                    smooth={true}
                                    duration={1500}
                                >
                                    <Button
                                        color="pink-gradient"
                                        className="mt-5 mt-md-4"
                                    >
                                        Kup dostęp taniej o 40%
                                    </Button>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                    <Row className="pt-5 mt-5 align-items-center text-center text-md-left">
                        <Col
                            xs={{ size: 12, order: 2 }}
                            md={{ size: 6, order: 1 }}
                            className="z-index-1"
                        >
                            <div className="description-box mt-5 mt-md-0 pt-4 pt-md-0">
                                <span className="tag">Nowa funkcja</span>
                                <h3 className="mt-5 mt-md-4">
                                    tylko{' '}
                                    <span>
                                        to, co <br /> lubisz
                                    </span>
                                </h3>
                                <p className="first-paragraph mt-3 mt-md-0">
                                    Zapisuj przepisy ulubionych posiłków, by móc
                                    dodać je do swojego jadłospisu, gdy tylko
                                    masz na to ochotę.
                                </p>
                                <p className="smaller-paragraph">
                                    Każdy z nas ma swoje ulubione dania, do
                                    których lubi co jakiś czas wrócić. Dzięki
                                    funkcji Ulubione posiłki to bardzo proste,
                                    bo wybrane przez siebie dania możesz
                                    kolekcjonować w jednym miejscu i mieć do
                                    nich jeszcze szybszy, łatwiejszy dostęp.
                                    Teraz dieta z aplikacją jest jeszcze
                                    prostsza w codziennym stosowaniu!
                                </p>
                                <Link
                                    to="buy"
                                    smooth={true}
                                    duration={1500}
                                >
                                    <Button
                                        color="pink-gradient"
                                        className="mt-5 mt-md-4"
                                    >
                                        Kup dostęp taniej o 40%
                                    </Button>
                                </Link>
                            </div>
                        </Col>
                        <Col
                            xs={{ size: 12, order: 1 }}
                            md={{ size: 6, order: 2 }}
                        >
                            <div className="box d-flex justify-content-end">
                                <div className="circle"></div>
                                <GatsbyImage
                                    image={
                                        data.favoriteMeal.childImageSharp
                                            .gatsbyImageData
                                    }
                                    className="w-90"
                                    alt=""
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <BackgroundImage
                Tag="section"
                fluid={gymHome}
                className="gym-home text-center d-none d-md-block"
            >
                <Container>
                    <Row>
                        <Col>
                            <p>Chcesz trenować w domu? Na dworze? Siłowni?</p>
                            <h2>
                                z aplikacją to <br />
                                <span>ty decydujesz!</span>
                            </h2>
                            <Link to="buy" smooth={true} duration={1500}>
                                <Button color="pink-gradient" className="mt-3">
                                    Kup dostęp taniej o 40%
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </BackgroundImage>
            <BackgroundImage
                Tag="section"
                fluid={gymHomeMobile}
                className="gym-home text-center d-md-none"
            >
                <Container>
                    <Row>
                        <Col>
                            <p>
                                Chcesz trenować w domu? Na dworze? <br />{' '}
                                Siłowni?
                            </p>
                            <h2>
                                z aplikacją to <br />
                                <span>ty decydujesz</span>
                            </h2>
                            <Link to="buy" smooth={true} duration={1500}>
                                <Button color="pink-gradient" className="mt-3">
                                    Kup dostęp taniej o 40%
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </BackgroundImage>
            <section className="animated-slides text-center">
                <GatsbyImage
                    image={data.workouts.childImageSharp.gatsbyImageData}
                    className="workouts-img d-md-none"
                    alt=""
                />
                <Container>
                    <Row>
                        <Col
                            xs={{ size: 12, order: 2 }}
                            md={{ size: 12, order: 1 }}
                            className="z-index-1"
                        >
                            <div className="description-box">
                                <div className="tag d-inline-block">Nowość</div>
                                <h3 className="mt-5 mt-md-4">
                                    90 dopasowanych <br />
                                    <span>planów treningowych</span>
                                </h3>
                                <p className="first-paragraph mt-3 mt-md-0">
                                    Jedna aplikacja lub 82 kg płyt DVD z
                                    treningami. Ty wybierasz, który dziś
                                    zaczniesz, a ich liczba regularnie rośnie!
                                </p>
                                <p className="smaller-paragraph">
                                    Dla początkujących i zaawansowanych. Dla
                                    kobiet, mężczyzn oraz par. Treningi
                                    wzmacniające, na super szybkie spalanie,
                                    cardio i tabata. Coś specjalnie dla młodych
                                    mam i na każdy trymestr ciąży. Opcje
                                    ćwiczeń ze sprzętem lub bez. Yoga i treningi
                                    łączące fitness ze sztukami walki oraz
                                    programy przygotowane z myślą o osobach 55+!
                                </p>
                                <p className="smaller-paragraph">
                                    {' '}
                                    Na pewno znajdziesz tu coś właśnie dla
                                    siebie!
                                </p>
                            </div>
                        </Col>
                        <Col
                            xs={{ size: 12, order: 1 }}
                            className="d-none d-md-block"
                        >
                            <GatsbyImage
                                image={
                                    data.workouts.childImageSharp
                                        .gatsbyImageData
                                }
                                className="workouts-img"
                                alt=""
                            />
                        </Col>
                    </Row>
                </Container>
                <div className="slideshow">
                    <div className="line1">
                        <GatsbyImage
                            image={
                                data.programsA.childImageSharp.gatsbyImageData
                            }
                            className=""
                            alt=""
                        />
                        <GatsbyImage
                            image={
                                data.programsA.childImageSharp.gatsbyImageData
                            }
                            className=""
                            alt=""
                        />
                    </div>
                    <div className="line2">
                        <GatsbyImage
                            image={
                                data.programsB.childImageSharp.gatsbyImageData
                            }
                            className=""
                            alt=""
                        />
                        <GatsbyImage
                            image={
                                data.programsB.childImageSharp.gatsbyImageData
                            }
                            className=""
                            alt=""
                        />
                    </div>
                </div>
            </section>
            <section className="new-functionalities text-center text-md-left pt-4 pt-md-3">
                <Container>
                    <Row className="align-items-center">
                        <Col
                            xs={{ size: 12, order: 2 }}
                            md={{ size: 6, order: 1 }}
                            className="z-index-1"
                        >
                            <div className="description-box mt-5 mt-md-0">
                                <span className="tag">Zawsze i wszędzie</span>
                                <h3 className="mt-5 mt-md-4">
                                    osobisty trener{' '}
                                    <span>na twoim nadgarstku</span>
                                </h3>
                                <p className="first-paragraph mt-3 mt-md-0">
                                    Zsynchronizuj swój Apple Watch z aplikacją,
                                    by wycisnąć z treningu jeszcze więcej.
                                </p>
                                <p className="smaller-paragraph">
                                    Podgląd ilości spalonych kalorii, kontrola
                                    tętna, przewijanie lub zatrzymywanie
                                    ćwiczeń, czy szybkie przełączanie piosenek
                                    na Twojej treningowej playliście. Dzięki
                                    wersji aplikacji na Apple Watch możesz
                                    jeszcze dokładniej monitorować swoje
                                    treningowe postępy i osiągnięcia.
                                </p>
                                <Link
                                    to="buy"
                                    smooth={true}
                                    duration={1500}
                                >
                                    <Button
                                        color="pink-gradient"
                                        className="mt-3"
                                    >
                                        Kup dostęp taniej o 40%
                                    </Button>
                                </Link>
                            </div>
                        </Col>
                        <Col
                            xs={{ size: 12, order: 1 }}
                            md={{ size: 6, order: 2 }}
                        >
                            <video
                                className="gif-ann"
                                aria-hidden="true"
                                muted
                                autoPlay
                                playsInline
                                loop
                            >
                                <source src={gifAnn} type="video/mp4" />
                            </video>
                        </Col>
                    </Row>
                    <Row className="pt-5 mt-5 align-items-center">
                        <Col md="6">
                            <div className="box" ref={phone}>
                                <div className="circle circle-pink"></div>
                                <GatsbyImage
                                    image={
                                        data.notification.childImageSharp
                                            .gatsbyImageData
                                    }
                                    alt=""
                                />
                                <img
                                    src={pushMessage}
                                    className={`${
                                        showPush && 'show'
                                    } push-message`}
                                />
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="description-box mt-5 mt-md-0">
                                <span className="tag">Nowość</span>
                                <h3 className="mt-5 mt-md-4">
                                    <span>już zawsze zdążysz</span>
                                    <br /> zrobić swój trening
                                </h3>
                                <p className="first-paragraph mt-3 mt-md-0">
                                    W każdy dzień treningowy aplikacja przypomni
                                    Ci o nadchodzącym treningu z Twoim ulubionym
                                    trenerem.
                                </p>
                                <p className="smaller-paragraph">
                                    Jednym z prostszych sposobów, żeby utrzymać
                                    swoją chęć do działania na wysokim poziomie,
                                    a jednocześnie wyeliminować z drogi wszelkie
                                    wymówki, jest korzystanie z automatycznych
                                    przypomnień o treningach.
                                </p>
                                <p className="smaller-paragraph">
                                    Teraz już na pewno nie opuścisz
                                    zaplanowanego treningu!
                                </p>
                                <Link
                                    to="buy"
                                    smooth={true}
                                    duration={1500}
                                >
                                    <Button
                                        color="pink-gradient"
                                        className="mt-5 mt-md-4"
                                    >
                                        Kup dostęp taniej o 40%
                                    </Button>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="motivation text-center text-md-left">
                <Container>
                    <Row className="align-items-center">
                        <Col
                            xs={{ size: 12, order: 2 }}
                            md={{ size: 6, order: 1 }}
                            className="z-index-1"
                        >
                            <div className="mt-5 mt-md-0">
                                <span className="tag">Wiedza i motywacja</span>
                                <h3 className="mt-5 mt-md-4">
                                    <span>motywacja</span>. twój klucz do
                                    sukcesu
                                </h3>
                                <p>
                                    Autorskie porady i artykuły dopasowane
                                    specjalnie do Ciebie. Dzięki nim utrzymasz
                                    swoją motywację na wysokim poziomie!
                                </p>
                            </div>
                        </Col>
                        <Col
                            xs={{ size: 12, order: 1 }}
                            md={{ size: 6, order: 2 }}
                        >
                            <div className="box">
                                <div className="circle"></div>
                                <GatsbyImage
                                    image={
                                        data.feedScreen.childImageSharp
                                            .gatsbyImageData
                                    }
                                    alt=""
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="breathing">
                <Container>
                    <Row className="align-items-center">
                        <Col xs="12" md="6">
                            <GatsbyImage
                                image={
                                    data.breathingIcons.childImageSharp
                                        .gatsbyImageData
                                }
                                alt=""
                            />
                        </Col>
                        <Col xs="12" md="6">
                            <div className="description-box text-center text-md-left mt-5 mt-md-0">
                                <span className="tag">HIT</span>
                                <h3 className="mt-5 mt-md-4">
                                    przenieś uwagę <br />
                                    <span>do wnętrza</span>
                                </h3>
                                <p className="first-paragraph mt-3 mt-md-0">
                                    Opanuj sztukę świadomego oddechu i przekonaj
                                    się, ile dzięki temu zyskasz.
                                </p>
                                <p className="smaller-paragraph">
                                    Oddech to jedno z najlepszych narzędzi do
                                    walki ze stresem: pomaga się uspokoić,
                                    skupić na chwili bieżącej lub opanować
                                    emocje. Właśnie dlatego oddech warto ćwiczyć
                                    i pracować nad tym, żeby był jak najbardziej
                                    świadomy.
                                </p>
                                <p className="smaller-paragraph">
                                    W aplikacji znajdziesz 3 zestawy ćwiczeń,
                                    które Ci w tym pomogą: Skupienie, Spokój i
                                    Harmonia.
                                </p>
                                <Link
                                    to="buy"
                                    smooth={true}
                                    duration={1500}
                                >
                                    <Button
                                        color="pink-gradient"
                                        className="mt-5 mt-md-4"
                                    >
                                        Kup dostęp taniej o 40%
                                    </Button>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="balance text-center text-md-left pt-md-0">
                <Container>
                    <Row className="align-items-center">
                        <Col
                            xs={{ size: 12, order: 2 }}
                            md={{ size: 6, order: 1 }}
                            className="z-index-1"
                        >
                            <div className="description-box mt-5 mt-md-0">
                                <span className="tag">Ulubiona funkcja</span>
                                <h3 className="mt-5 mt-md-4">
                                    złap swój <br />
                                    <span>balans</span>
                                </h3>
                                <p className="first-paragraph mt-3 mt-md-0">
                                    Zdystansuj się od codziennych spraw i znajdź
                                    wewnętrzny spokój.
                                </p>
                                <p className="smaller-paragraph">
                                    Balans to wyjątkowe miejsce w aplikacji Diet
                                    &amp; Training by Ann wypełnione nagraniami
                                    prosto ze świata przyrody oraz autorską,
                                    relaksacyjną muzyką. Dzięki temu w dowolnym
                                    momencie dnia możesz poczuć się tak, jakbyś
                                    właśnie spacerowała po lesie, siedziała przy
                                    ognisku lub była nad brzegiem morza.
                                    Przygotowaliśmy dla Ciebie aż 20+
                                    wyjątkowych ścieżek!
                                </p>
                                <Link
                                    to="buy"
                                    smooth={true}
                                    duration={1500}
                                >
                                    <Button
                                        color="pink-gradient"
                                        className="mt-5 mt-md-4"
                                    >
                                        Kup dostęp taniej o 40%
                                    </Button>
                                </Link>
                            </div>
                        </Col>
                        <Col
                            xs={{ size: 12, order: 1 }}
                            md={{ size: 6, order: 2 }}
                        >
                            <div className="box">
                                <div className="circle circle-pink"></div>
                                <GatsbyImage
                                    image={
                                        data.balanceIcons.childImageSharp
                                            .gatsbyImageData
                                    }
                                    alt=""
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="added p-md-0 text-center text-md-left pb-0">
                <Container>
                    <Row className="align-items-center">
                        <Col
                            xs={{ size: 12, order: 2 }}
                            md={{ size: 6, order: 1 }}
                            className="d-none d-md-block"
                        >
                            <GatsbyImage
                                image={
                                    data.phone.childImageSharp.gatsbyImageData
                                }
                                alt=""
                            />
                        </Col>
                        <Col
                            xs={{ size: 12, order: 1 }}
                            md={{ size: 6, order: 2 }}
                        >
                            <div className="description-box">
                                <span className="tag">Dodaliśmy</span>
                                <h3 className="mt-5 mt-md-4">
                                    <span>
                                        820 nowych <br /> posiłków.{' '}
                                    </span>
                                    spróbujesz wszystkich?
                                </h3>
                                <p className="first-paragraph mt-3 mt-md-0">
                                    Proste przepisy, sezonowe produkty i
                                    oryginalne połączenia.
                                </p>
                                <p className="smaller-paragraph">
                                    Z diety w aplikacji możesz korzystać
                                    miesiącami i codziennie poznawać zupełnie
                                    nowe, inne smaki. Różnorodność to bardzo
                                    ważna zasada zdrowej diety, dlatego tylko w
                                    tym roku dodaliśmy do aplikacji aż 820
                                    nowych przepisów.
                                </p>
                                <Link
                                    to="buy"
                                    smooth={true}
                                    duration={1500}
                                >
                                    <Button
                                        color="pink-gradient"
                                        className="mt-3 mb-5 mb-mb-0"
                                    >
                                        Kup dostęp taniej o 40%
                                    </Button>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <GatsbyImage
                    image={data.phone.childImageSharp.gatsbyImageData}
                    alt=""
                    className="d-md-none"
                />
            </section>
            <section className="trainers-slider">
                <Container>
                    <h2 className="text-center">
                        twoi <span>trenerzy</span>
                    </h2>

                    <div className="slider-container">
                        <Slider ref={slider} {...settings}>
                            <div>
                                <div className="trainer">
                                    <div className="trainer-photo">
                                        <GatsbyImage
                                            image={
                                                data.ania.childImageSharp
                                                    .gatsbyImageData
                                            }
                                            alt=""
                                        />
                                        <div className="trainer-info">
                                            <p>Treningi funkcjonalne</p>
                                            <h4 className="mt-2">
                                                Anna Lewandowska
                                            </h4>
                                        </div>
                                    </div>
                                    <p className="mt-3">
                                        Wielokrotna medalistka mistrzostw
                                        świata, Europy i Polski w karate
                                        tradycyjnym. Była reprezentantka Polski
                                        w karate. Dyplomowana trenerka fitness i
                                        TRX. Z wykształcenia manager sportowy i
                                        dietetyk.
                                    </p>
                                </div>
                            </div>

                            <div>
                                <div className="trainer">
                                    <div className="trainer-photo">
                                        <GatsbyImage
                                            image={
                                                data.joanna.childImageSharp
                                                    .gatsbyImageData
                                            }
                                            alt=""
                                        />
                                        <div className="trainer-info">
                                            <p>
                                                Treningi z elementami sztuk
                                                walki
                                            </p>
                                            <h4 className="mt-2">
                                                Joanna Jędrzejczyk
                                            </h4>
                                        </div>
                                    </div>
                                    <p className="mt-3">
                                        Reprezentantka Polski w boksie,
                                        kick-boxingu, muay thai oraz mieszanych
                                        sztukach walki (MMA). Trzykrotna
                                        zawodowa mistrzyni świata. Pierwsza
                                        Polka w prestiżowej organizacji Ultimate
                                        Fighting Championship.
                                    </p>
                                </div>
                            </div>

                            <div>
                                <div className="trainer">
                                    <div className="trainer-photo">
                                        <GatsbyImage
                                            image={
                                                data.mariola.childImageSharp
                                                    .gatsbyImageData
                                            }
                                            alt=""
                                        />

                                        <div className="trainer-info">
                                            <p>Treningi dla dojrzałych</p>
                                            <h4 className="mt-2">
                                                Mariola Bojarska - Ferenc
                                            </h4>
                                        </div>
                                    </div>
                                    <p className="mt-3">
                                        Absolwentka Akademii Wychowania
                                        Fizycznego w Warszawie. Była członkini
                                        kadry narodowej w gimnastyce
                                        artystycznej. Instruktorka fitness z
                                        wieloletnim doświadczeniem, członkini
                                        najważniejszych światowych organizacji
                                        fitness. Swoje treningi kieruje przede
                                        wszystkim do osób dojrzałych.
                                    </p>
                                </div>
                            </div>

                            <div>
                                <div className="trainer">
                                    <div className="trainer-photo">
                                        <GatsbyImage
                                            image={
                                                data.ewelina.childImageSharp
                                                    .gatsbyImageData
                                            }
                                            alt=""
                                        />
                                        <div className="trainer-info">
                                            <p>Yoga</p>
                                            <h4 className="mt-2">
                                                Ewelina Godlewska
                                            </h4>
                                        </div>
                                    </div>
                                    <p className="mt-3">
                                        Dyplomowana nauczycielka yogi z ponad
                                        10-letnim doświadczeniem. Z
                                        wykształcenia psycholog i instruktor
                                        rekreacji ruchowej o specjalności
                                        ćwiczenia psychofizyczne w oparciu o
                                        system hatha yogi.
                                    </p>
                                </div>
                            </div>

                            <div>
                                <div className="trainer">
                                    <div className="trainer-photo">
                                        <GatsbyImage
                                            image={
                                                data.kuba.childImageSharp
                                                    .gatsbyImageData
                                            }
                                            alt=""
                                        />
                                        <div className="trainer-info">
                                            <p>Treningi dla mężczyzn i par</p>
                                            <h4 className="mt-2">Jakub Głąb</h4>
                                        </div>
                                    </div>
                                    <p className="mt-3">
                                        Mistrz Polski w Fitness Sylwetkowym,
                                        wielokrotny Mistrz Polski, Europy i
                                        Świata w Karate Tradycyjnym. Z zawodu
                                        trener personalny dietetyk, instruktor
                                        samoobrony i fitness, certyfikowany
                                        trener TRX.
                                    </p>
                                </div>
                            </div>
                        </Slider>
                        <button
                            className="slider-btn prev"
                            onClick={handlePrev}
                        >
                            <img
                                src={arrow}
                                className="rotate180deg"
                                alt="wstecz"
                            />
                        </button>
                        <button
                            className="slider-btn next"
                            onClick={handleNext}
                        >
                            <img src={arrow} alt="dalej" />
                        </button>
                    </div>
                </Container>
                <Container>
                    <Row>
                        <Col>
                            <Link to="buy" smooth={true} duration={1500}>
                                <Button
                                    color="pink-gradient"
                                    className="d-block mx-auto"
                                >
                                    Kup dostęp taniej o 40%
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="your-application">
                <Container>
                    <h2 className="text-center">
                        twoja <span>aplikacja</span>
                    </h2>
                    <Row className="align-items-center">
                        <Col
                            xs={{ size: 6, order: 2 }}
                            md={{ size: 3, order: 1 }}
                            className="mt-5 mt-md-0"
                        >
                            <div className="text-center mb-4">
                                <img src={changeMealIcon} alt="" />
                                <p>
                                    Wymiana <br /> składników
                                </p>
                            </div>

                            <div className="text-center mb-4">
                                <img src={listIcon} alt="" />
                                <p>Interaktywna lista zakupów</p>
                            </div>

                            <div className="text-center mb-4">
                                <img src={dietitianIcon} alt="" />
                                <p>Opieka dietetyków i trenerów online</p>
                            </div>

                            <div className="text-center mb-4">
                                <img src={menuIcon} alt="" />
                                <p>
                                    Jadłospisy
                                    <br />
                                    okolicznościowe
                                </p>
                            </div>
                        </Col>
                        <Col
                            xs={{ size: 12, order: 1 }}
                            md={{ size: 6, order: 2 }}
                        >
                            <div className="position-relative text-center">
                                <div className="circle">
                                    <div className="circle-gradient" />
                                </div>
                                <GatsbyImage
                                    image={
                                        data.dietTraining.childImageSharp
                                            .gatsbyImageData
                                    }
                                    alt=""
                                />
                            </div>
                        </Col>
                        <Col
                            xs={{ size: 6, order: 3 }}
                            md={{ size: 3, order: 3 }}
                            className="mt-5 mt-md-0"
                        >
                            <div className="text-center mb-4">
                                <img src={workoutIcon} alt="" />
                                <p>Ponad 4500 dni treningowych</p>
                            </div>

                            <div className="text-center mb-4">
                                <img src={measurementIcon} alt="" />
                                <p>Pomiary i śledzenie postępów</p>
                            </div>

                            <div className="text-center mb-4">
                                <img src={adviceIcon} alt="" />
                                <p>Rady i przypomnienia o treningach</p>
                            </div>

                            <div className="text-center mb-4">
                                <img src={dumbbellIcon} alt="" />
                                <p>Możliwości ćwiczenia ze sprzętem lub bez</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="products pb-0" id="products">
                <Container className="d-none d-md-block">
                    <Row className="justify-content-center align-items-center">
                        <Col md="6">
                            <h1 className="m-0">
                                {isCyberMonday ? 'Cyber' : 'Black'}
                            </h1>
                        </Col>
                        <Col md="3">
                            <h1>
                                <span className="transparent-text">2021</span>
                            </h1>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col md="3">
                            {' '}
                            <div className="coupon-wrapper">
                                <img src={coupon} alt="" />
                            </div>
                        </Col>
                        <Col md="5">
                            <h1 className="friday">
                                {isCyberMonday ? 'Monday' : 'Friday'}
                            </h1>
                            <p className="mt-5 ml-2">
                                Wybierz dostęp dla siebie podczas największej
                                promocji w roku, która
                            </p>
                            <span className="ml-2 continues-now">
                                trwa teraz
                            </span>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <div className="text-center my-5 py-5">
                            <img src={separator} alt="" className="w-100" />
                        </div>
                    </Row>
                </Container>

                <Container className="d-md-none">
                    <div className="title-box mx-auto ">
                        <h1 className="mb-0 with-tag">
                            {isCyberMonday ? 'Cyber' : 'Black'}{' '}
                            <span className="transparent-text">2021</span>
                        </h1>
                        <h1>{isCyberMonday ? 'Monday' : 'Friday'}</h1>
                    </div>
                    <Row>
                        <Col xs={{ offset: 4 }}>
                            <p className="mt-5 pt-4 ml-2 px-3">
                                Wybierz dostęp dla siebie podczas największej
                                promocji w roku, która
                            </p>
                            <span className="ml-2 continues-now px-3">
                                trwa teraz
                            </span>
                        </Col>
                    </Row>
                </Container>

                <div className="text-center my-5 py-5 d-md-none">
                    <img src={separator} alt="" className="w-100" />
                </div>
            </section>

            <SaleProductsList
                title={false}
                productsGroups={productsGroups}
                source="mariola"
                descriptionFirst
                buttonText="Kup w promocji"
                showOldPrice
            >
                <h2 className="text-center">
                    Wybierz pakiet i <span className="pink">zacznij dziś!</span>
                </h2>
            </SaleProductsList>
        </Layout>
    );
};

export default Page;

export const query = graphql`
    query {
        hero: file(relativePath: { eq: "black2021/hero-img.jpg" }) {
            ...fluidBackground
        }
        heroMobile: file(
            relativePath: { eq: "black2021/first_screen_bg_mob.jpg" }
        ) {
            ...fluidBackground
        }
        trainers: file(relativePath: { eq: "black2021/trainers_hpba.png" }) {
            ...fluidImage1920
        }
        trainersMob: file(
            relativePath: { eq: "black2021/trainers_hpba_mob.png" }
        ) {
            ...fluidImage1920
        }
        mealDetails: file(relativePath: { eq: "black2021/strong.png" }) {
            ...fluidImage1920
        }
        gymHome: file(relativePath: { eq: "black2021/home_gym_bg.jpg" }) {
            ...fluidBackground
        }
        gymHomeMobile: file(
            relativePath: { eq: "black2021/home_gym_bg_mobile.jpg" }
        ) {
            ...fluidBackground
        }

        workouts: file(relativePath: { eq: "black2021/workouts_screen.png" }) {
            ...fluidImage1920
        }
        programsA: file(
            relativePath: { eq: "black2021/training_programs_1.png" }
        ) {
            ...fluidImage1920
        }
        programsB: file(
            relativePath: { eq: "black2021/training_programs_2.png" }
        ) {
            ...fluidImage1920
        }
        notification: file(
            relativePath: { eq: "black2021/notification_screen.png" }
        ) {
            ...fluidImage1920
        }
        feedScreen: file(relativePath: { eq: "black2021/feed_screen.png" }) {
            ...fluidImage1920
        }
        breathingIcons: file(
            relativePath: { eq: "black2021/breathing_icons.png" }
        ) {
            ...fluidImage1920
        }
        balanceIcons: file(
            relativePath: { eq: "black2021/balance_icons.png" }
        ) {
            ...fluidImage1920
        }
        phone: file(relativePath: { eq: "black2021/diet_img_bg.jpg" }) {
            ...fluidImage1920
        }
        ania: file(relativePath: { eq: "black2021/Ania.png" }) {
            ...fluidImage1920
        }
        ewelina: file(relativePath: { eq: "black2021/Ewelina.png" }) {
            ...fluidImage1920
        }
        mariola: file(relativePath: { eq: "black2021/Mariola.png" }) {
            ...fluidImage1920
        }
        kuba: file(relativePath: { eq: "black2021/Kuba.png" }) {
            ...fluidImage1920
        }
        ania: file(relativePath: { eq: "black2021/Ania.png" }) {
            ...fluidImage1920
        }
        joanna: file(relativePath: { eq: "black2021/Joanna.png" }) {
            ...fluidImage1920
        }
        dietTraining: file(
            relativePath: { eq: "black2021/diet_and_training.png" }
        ) {
            ...fluidImage1920
        }
        favoriteMeal: file(
            relativePath: { eq: "black2021/favorite_meal_screen.png" }
        ) {
            ...fluidImage1920
        }
        mealDetailsPhone: file(
            relativePath: { eq: "black2021/meal_details_phone.png" }
        ) {
            ...fluidImage1920
        }
    }
`;
